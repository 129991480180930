import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
              The research, analysis, and insights provided by Sheer Analytics and Insights are based on available
information and data, and are provided for informational purposes only. Sheer Analytics and Insights
makes no warranties or representations about the accuracy, completeness, or reliability of the
information provided. The research, analysis, and insights are not intended to be used as
professional advice and should not be relied upon as such. Users should seek the advice of a
qualified professional before making any decisions based on the information provided by Sheer
Analytics and Insights. Sheer Analytics and Insights shall not be liable for any damages arising from
the use of the information provided, including but not limited to direct, indirect, incidental, punitive,
and consequential damages. Sheer Analytics and Insights reserves the right to modify or update the
information provided at any time without prior notice.       
              </p>
              </div>
        </div>
    </section>
  )
}

import React from "react"

import Disclaimer from "../components/Disclaimer/Home"
import SEO from "../components/Base/SEO"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"

export default function () {
  return (
    <Layout>
      <SEO title={"sheer analytics"} />
      <InnerPageBanner headingText={"Disclaimer"} linkText={"Disclaimer"} />
      <Disclaimer />
    </Layout>
  )
}